.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*

#123740	(18,55,64)
#549aab	(84,154,171)
#b0d7e1	(176,215,225)
#f6f6f6	(246,246,246)
#f1802d	(241,128,45)

#2a312a
#28382b
#455b46
#f4900e
#f7631c
#ed5005


*/

.App-header {
  background-color: #f1802d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #123740;
}

.App-link {
  color: #b0d7e1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-down {
  position: relative;
  min-width: 65vw;
  display: flex;
  justify-content: center;
  bottom: 0px; 
}

@media (pointer:none), (pointer:coarse) {
  .footer-down {
    min-width: 100vw;
  }
}